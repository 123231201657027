import React from 'react';
import '../styles/ProjectPreviewPopUp.css'

import {isVideo} from "../utils";
import VideoPlayer from "./VideoPlayer";

function ProjectPreviewPopUp({asset, setPreviewPopUp}) {
    return (
        <div className={'ProjectPreviewPopUp'}
             style={{display: 'flex', justifyContent: 'center', alignItems: "center"}}>
            <div className={'black-background'} onClick={() => setPreviewPopUp('')}></div>
                {
                    isVideo(asset) ?
                        (() => {
                            let assetInListForm = asset.split('.')
                            return <VideoPlayer
                                autoplay={false}
                                videoID={assetInListForm[assetInListForm.length - 2]}
                                height={800}
                                width={1000}
                                style={{position: "relative", top: "-3vh"}}
                            />
                        })()
                        :
                        <img style={{height: "fit-content", position:"relative", top:"-3vh"}} src={asset} alt={""}/>
                }
        </div>
    );
}

export default ProjectPreviewPopUp;
import React, {useRef, useState} from 'react';
import emailjs from '@emailjs/browser'
import '../styles/EmailSender.css'

const EMAIL_LIMIT = 5
const LIMIT_HOURS = 24
const EMAIL_COUNT_STORAGE_KEY = 'lastFiveEmailTimestamps'
const SERVICE_ID = "service_opk61w3"
const TEMPLATE_ID = "template_inr1jre"
const PUBLIC_KEY = "e0g2gVdQDOZhcOcAw"

function EmailSender() {
    const [emailIsSent, setEmailIsSent] = useState(false)
    const formRef = useRef()

    const [lastFiveEmailTimestamps, setLastFiveEmailTimestamps] = useState(()=>{
        const lastFiveEmails = localStorage.getItem(EMAIL_COUNT_STORAGE_KEY);
        return lastFiveEmails ? JSON.parse(lastFiveEmails) : [];
    }); // [...Date]


    function updateInputValue(value, update) {
        try{
            update(value)
        }catch (e){
            console.error('Incorrect method: update')
        }
    }

    function emailCountIsOverLimit(){
        if(lastFiveEmailTimestamps >= EMAIL_LIMIT && lastFiveEmailTimestamps[0] < Date.now() - LIMIT_HOURS * 1000 * 60 * 60){
            alert('You have sent too many emails in the past 24 hours. Please try again later.');
            return true;
        }
        return false;
    }

    function updateEmailTimestamp(){
        const newTimestamp = [Date.now(), ...lastFiveEmailTimestamps]
        if(newTimestamp.length > 5) newTimestamp.pop()
        setLastFiveEmailTimestamps(newTimestamp)
        localStorage.setItem(EMAIL_COUNT_STORAGE_KEY, JSON.stringify(newTimestamp));
    }

    function sendEmail(e) {
        e.preventDefault()

        if(emailCountIsOverLimit()){
            alert('You have sent too many emails in the past 24 hours.');
            return;
        }
        const formData = new FormData(formRef.current);
        const formValues = Object.fromEntries(formData.entries());
        console.log(formValues);

        emailjs
            .sendForm(SERVICE_ID, TEMPLATE_ID, formRef.current, PUBLIC_KEY)
            .then(()=>{
                setEmailIsSent(true);
                alert('Email sent successfully.');
                updateEmailTimestamp();
            })
            .catch(()=>{
                alert('Email sent failed, try again later.');
            })
    }

    return (
        <form ref={formRef} className={'EmailSender'} onSubmit={sendEmail}>
            <label>
                Name: <br/>
                <input type={'text'}
                       name={'from_name'}
                       placeholder={'Enter your name'} required={true}/>
            </label>

            <div>
                <label htmlFor="">
                    Email: <br/>
                    <input type="email" name={'reply_to'} placeholder={'Enter your email'} required={true}/>
                </label>

                <label htmlFor="">
                    Phone Number:<br/>
                    <input type="text" name={'phone'} placeholder={'Optional'} required={false}/>
                </label>
            </div>

            <label htmlFor="">
                Subject:<br/>
                <input type="text" name={'subject'} placeholder={'Type the subject'} required={true}/>
            </label>

            <label htmlFor="">
                Message:<br/>
                <textarea name={'message'} placeholder={'Type your message here...'}/>
            </label>

            <div>
                {emailIsSent?
                    <span>
                    Email sent!
                </span> : <span></span>}
                <button>Send</button>
            </div>
        </form>
    );
}

export default EmailSender;
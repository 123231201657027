import React from 'react';

function NoPage() {
    return (
        <>
            <h1>404 no found</h1>
        </>
    );
}

export default NoPage;
import React from 'react';

function BackgroundImage() {
    return (
        <div className={'background-image'}>
            <div className={'brightness'}></div>
        </div>
    );
}

export default BackgroundImage;